


.foldernav-menu {
    display: flex;
    flex-direction: row;
    list-style: none;
    justify-content: center;
    align-items: center;
    gap: 16px;
    overflow-x: auto;
    overflow-y: hidden;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    
   
}

.foldernav {
    margin-bottom: 1rem;
}

.foldernav-menu .active-link {
    color: #505050;;
    text-decoration: none;
    border: 1px solid #DEE2E6;
    border-radius: 4px 4px 0px 0px;
    border-top: 1px solid #DEE2E6;
    background: #FFFFFF;
    padding: .5rem;
}

.foldernav-menu .inactive-link {
    color: #098BEF;
    text-decoration: none;
}

.name {
    display: flex;
    padding-top: 2.4rem;
    margin-left: 9rem;
    color: #505050;
    
}