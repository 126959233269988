

.table-container{
    overflow-x: auto;
    padding-bottom: 2rem;
    display: flex;
    justify-content: center;
    margin: 0px;
    padding: 0;
    margin-right: 0px;
    margin-bottom: 2rem;
    margin-left: 4rem;
    
    width: 90%;


}

table {
    border-collapse: collapse;
    width: 80vw;
    text-align: left;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    table-layout: auto;
    box-sizing:border-box;
    padding: 0;
    
}


.caption {
    margin-top: 4rem;
    font-weight: 700;
    text-align:left;
    padding-bottom: 30px;
    color: #505050;
    font-size: 18px;
    line-height: 23px;
}

table th{
    
    font-weight: 600;
    color: #0367B4;
    font-size: 16px;
    line-height: 21px;
    padding: 1vh;
    

}

table td {
    border: 1px solid #C2C9D1;;
    color: #000000;
    font-weight: 400;
    padding: 0vh;
    white-space: nowrap;
    overflow: hidden; 
    font-size: 14px;
    max-width: 28vw;
    overflow-x: auto;
    padding-left: 1vh;
    padding-right: 1vh;
    


}
.circle-container {
    display:flex;
    padding: .5vh;
    
}

.circle-container img {
  margin: .2vh;
  margin-right: 1vh;
}


  

  .legend-container {
    display:flex;
    flex-direction: row;
    align-items: flex-start;
    text-align: center;
    justify-content: center;
    border: 1px solid #D6DBE0;
    padding: 1rem;
    width: 40rem;
    margin: 0 auto;
    margin-bottom: 4vh;

  }

  .legend-row {
    display: flex;
    flex-direction: row;
    justify-content:left;
    padding: .5vh;
    font-size: 14px;
    line-height: 30px;
  }

  .legend-column {
    display: flex;
    flex-direction: column;
  }

  .legend-row img {
    margin-right: 1vh;
  }

  .legend-row .text {
    margin-right: 1.5vh;
  }

  .overall-container {
    width: 100%;
    
  }

  