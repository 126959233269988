* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
  }

 .body {
    min-height: 100vh;
    margin-bottom: -40px;
    padding-bottom: 20px;
  }

  .footer, push {
    width: 100%;
    height: 40px;
    background: #0367B4;
    
   
  
  }