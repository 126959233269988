.dropdown-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 3rem;
    
}

.dropdown-container select {
    background: #FFFFFF;
    border: 1px solid #D6DBE0;
    border-radius: 4px;
}

.bleedorinfect-dropdown {
    width: 25rem;
    

}

.incordec-dropdown {
    width: 25rem;
    

}
.heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top:2rem;
    margin-left:2rem;
    gap: 12.5rem;
}

.heading-font {
    font-size: 30px;
    line-height: 38px;
    display: block;
    align-items: center;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    color: #0367B4;
    margin-left:9rem;
    margin-top:1rem;
    overflow-y: auto;

}

.subheading-font {
    display: block;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #000000;
}

.body {
    min-height: 100vh;
    margin-bottom: -40px;
  }

.padding {
    padding-bottom: 3rem;
}

.footer, push {
    width: 100%;
    height: 40px;
    background: #0367B4;
   
}