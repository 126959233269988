.recommendations {
    display: flex;
    flex-direction: row;
    margin: 0;
    margin-bottom: 3rem;
    position: absolute;
    left:9rem;
    margin-top:1rem;
    margin-bottom: 1rem;
    order:1;
    

}

.outer-rec-container {
    display: flex;
    flex-direction: column;

    
    
}
.alert-container {
    display: inline-block;
    align-self: center;
    background: #CCE5FF;
    border: 1px solid #B8DAFF;
    border-radius: 4px;
    width: auto;
    padding: 1vh;
    order:2;
    margin-top: 13rem;
    color: #004085;
    transition: opacity 0.5s;
}



.bleed-rec {
    display: flex;
    order: 2;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
    width:25rem;
    margin-right:6rem;


}

.infection-rec {
    display: flex;
    order:3;
    width:25rem;

    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);

}



.bloodprep-rec {
    display: flex;
    order:1;
    width:25rem;
    margin-right: 6rem;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);


}

.rect-group {
    display: flex;
    flex-direction: row;
}




.rectangle {

    padding: 0px;
    margin: 0;
    width:2rem;
    height: 4rem;
    display: inline-flex;
    margin-right: 1rem;
    
}

.formlabel {
    display: flex;
    justify-content: center;
}
