.chart-container {
    display: flex;
    justify-content: center;
}

.legend {
    display: flex;
    flex-direction: row;
    padding: 2px;
    height: 2rem;
    justify-content: space-evenly;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 10.8692px;
    line-height: 13px;
    
}

.block {
    display: flex;
    flex-direction: row;
    
}

.legend-square {
    width: 10.92px;
    height: 10.92px;
    display: flex;
    margin-right: .5rem;
}