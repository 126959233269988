

.background {
    top: 0px;
    left: 0px;
    background: #E4E4E4;
    position: absolute;
    height:100%;
    width:100%;
}

form {
    display:flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    width: 351px;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
    
    
}

.top-margin {
    display:flex;
    flex-direction: row;
    background: #0367B4;
    padding: 0px;
    height: 30%;
    
}

.logo-container{
    background: #0367B4;
    display:flex;
    align-items: center;
    justify-content: center;
    height: 60%;
    width: 100%;
    
    padding: 1rem;
}

.input-area {
    background: #FFFFFF;
    display:flex;
    flex-direction: column;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 2rem;
    padding-bottom: 1rem;
}

.input-area label {
    display:block;
    color: #333333;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    padding-top: .5rem;
    padding-bottom: .5rem;
    
}

.input-area input {
    width: 95%;
    height:5vh;
    font-size: 16px;
    border: 1px solid #CED4DA;
    border-radius: 4px;
    color: #6C757D;
    text-indent: 1.5vh;
    
}

.help-link {
    padding-top: .5rem;
    padding-bottom: 1rem;
}


.help-link a:link, a:visited {
    color: #0367B4;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    text-decoration: none;
}

.submit button {
    background: #0367B4;
    border-radius: 4.8px;
    color: white;
    height: 40px;
    width: 100%;
    cursor:pointer;
    border: none;
}

.submit {
    width: 98%;
    background: white;
    cursor:pointer;
    padding-top:1rem;
    padding-bottom: 1.3rem;
}

.login-alert {
    display: inline-block;
    justify-content: center;
    text-align: center;
    align-self: center;
    border-radius: 4px;
    padding: 1vh;
    order:2;
    background: #CCE5FF;
    border: 1px solid #B8DAFF;
    color: #004085;
    transition: opacity 0.5s;
    width: 351px;
}